import React, { useState } from "react";
// import { Container } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import api from "../../services/api";
import moment from "moment";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4),
  },
});

const ExcludeEmail = () => {
  const [emails, setEmails] = useState([]);
  const [email, setEmail] = useState("");

  const deleteDripEmail = async (listId, rowEmail) => {
    const w = window.confirm(
      "Are you sure to delete this email from this list?"
    );
    console.log(listId, rowEmail);
    if (w) {
      try {
        await api.delete(
          `/api/v1/admin/search-email?email=${rowEmail}&listId=${listId}`
        );
        const newEmails = emails.filter((item) => item._id !== listId);
        setEmails(newEmails);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emails = (await api.get(`/api/v1/admin/search-email?email=${email}`))
      .data;
    setEmails(emails);
  };

  return (
    <Container style={{ marginTop: "100px" }}>
      <Typography component="h1" variant="h4" style={{ textAlign: "center" }}>
        Search Emails
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <form
          style={{
            marginTop: 50,
            width: 400,
          }}
          onSubmit={handleSubmit}
        >
          <TextField
            type="text"
            placeholder="Email"
            fullWidth
            value={email}
            name="email"
            onChange={(e) => setEmail(e.target.value)}
          />

          <Button
            type="submit"
            disabled={!email}
            style={{ margin: "5px" }}
            variant="contained"
            fullWidth
            color="primary"
          >
            Search
          </Button>
        </form>
        <TableContainer
          component={Paper}
          style={{
            marginTop: 20,
            marginBottom: 80,
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell align="right">List Name</TableCell>
                <TableCell align="right">Last Send</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {emails.map((row) => (
                <TableRow
                  key={row._id}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.emails[0].email}
                  </TableCell>
                  <TableCell align="right">{row.category}</TableCell>
                  <TableCell align="right">
                    {moment(row.emails[0].updatedAt).format("MMM DD YYYY")}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      type="submit"
                      style={{ margin: "5px" }}
                      variant="contained"
                      fullWidth
                      onClick={() =>
                        deleteDripEmail(row._id, row.emails[0].email)
                      }
                      color="secondary"
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Container>
  );
};

export default withStyles(styles)(ExcludeEmail);
