import React from 'react';
import { Container, Typography, TextField, Button, Fab } from '@material-ui/core';
import { useInputValue } from '../../utils/hooks';
import ChipInput from 'material-ui-chip-input';
import api from "../../services/api";

const AddBasicEmail = (props) => {
  const {
    location: { state }
  } = props;

  const [emails, setEmails] = React.useState(state ? state.data.emails : []);
  const [emailSender, setEmailSender] = React.useState(state ? state.data.emailSender : []);
  const [throttle, setThrottle] = React.useState(state ? state.data.throttle : 0);
  const [isSmtp, setIsSmtp] = React.useState(state ? (state.data.sendgridKey ? false : true) : false);
  const [sendgridKey, setSendgridKey] = React.useState(state ? state.data.sendgridKey : '');
  const [smtp, setSmtp] = React.useState(state ? state.data.smtp : { host: '', port: '', user: '', pwd: '' });
  const [text, setText] = React.useState(state ? state.data.text : '');
  const [subject, setSubject] = React.useState(state ? state.data.subject : '');
  const emailCsv = useInputValue('', false, true);
  const [newEmails, setNewEmails] = React.useState([]);
  const [delEmails, setDelEmails] = React.useState([]);
  const [category, setCategory] = React.useState(state ? state.data.category : '');

  const saveBasicEmail = async () => {
    await api.post('/api/v1/admin/basic-email', { emails, category, throttle, sendgridKey, smtp, emailSender, subject, text });
    props.history.push('/basic-email');
  };

  const editBasicEmail = async (emailsDup = false) => {
    await api.put(`/api/v1/admin/basic-email/${state.data._id}`, 
      { emails: emailsDup ? emailsDup : newEmails, category, throttle, sendgridKey, smtp, emailSender, subject, text, delEmails }
    );
    props.history.push('/basic-email');
  };

  const onChangeSmtp = (e) => {
    const newSmtp = {...smtp};
    newSmtp[e.target.name] = e.target.value;
    setSmtp(newSmtp);
  }

  const uploadCSV = async (data) => {
    try {
      let formData = new FormData();
      formData.append('emailCsv', data)
      const res = await api.post(`/api/v1/admin/basic-email-csv/${state.data._id}`, formData);
      if (res.data.duplicates.length) {
        const conf = window.confirm(
          `${res.data.duplicates.join()} exists. Would you still like to import them?`
        );
        if (conf) {
          const emailsDup = res.data.duplicates.map(item => item.replaceAll(' ', '').split('-')[0])
          setEmails([...emails, ...emailsDup]);
          await editBasicEmail(emailsDup);
        }
      } else {
        props.history.push("/basic-email");
      }
    } catch (err) {
      if (err.response) {
        const conf = window.confirm(
          `${err.response.data.message}`
        );
        if (!conf) return;
      }
    }
  }

  return (
    <>
      <Container style={{marginTop: '100px'}}>
        <Typography component="h1" variant="h4" style={{textAlign: 'center'}}>Add Basic Email</Typography>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
          <TextField 
            label="Category name"
            style={{
              width: '400px',
              padding: '5px'
            }}
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />
          <TextField 
            label="Daily Throttle"
            type="number"
            style={{
              width: '400px',
              padding: '5px'
            }}
            value={throttle}
            onChange={(e) => setThrottle(e.target.value)}
          />
          <div>
            <label>smtp</label>
            <input type="checkbox" checked={isSmtp} onChange={() => {
              setIsSmtp(!isSmtp);
              setSmtp({ host: '', port: '', user: '', pwd: '' });
              setSendgridKey('');
            }}/>
            <label>sendgrid</label>
            <input type="checkbox" checked={!isSmtp} onChange={() => {
              setIsSmtp(!isSmtp)
              setSmtp({ host: '', port: '', user: '', pwd: '' });
              setSendgridKey('');
            }}/>
          </div>
          {isSmtp ? (
            <>
              <TextField 
                label="Host"
                style={{
                  width: '400px',
                  padding: '5px'
                }}
                onBlur={async (e) => {
                  try {
                    await api.post('/api/v1/admin/basic-email/smtp', { host: e.target.value });
                  } catch (err) {
                    if (err.response && err.response.status) {
                      const conf = window.confirm(
                        `Smtp is already used`
                      );
                      if (!conf) return;
                    }
                  }
                }}
                name="host"
                value={smtp.host}
                onChange={onChangeSmtp}
              />
              <TextField 
                label="Port"
                type="number"
                style={{
                  width: '400px',
                  padding: '5px'
                }}
                name="port"
                value={smtp.port}
                onChange={onChangeSmtp}
              />
              <TextField 
                label="user"
                style={{
                  width: '400px',
                  padding: '5px'
                }}
                name="user"
                value={smtp.user}
                onChange={onChangeSmtp}
              />
              <TextField 
                label="password"
                style={{
                  width: '400px',
                  padding: '5px'
                }}
                name="pwd"
                value={smtp.pwd}
                onChange={onChangeSmtp}
              />
            </>
          ) : (
            <>
              <TextField 
                label="Sendgrid Key"
                style={{
                  width: '400px',
                  padding: '5px'
                }}
                onBlur={async (e) => {
                  try {
                    await api.post('/api/v1/admin/basic-email/sendgrid', { host: e.target.value });
                  } catch (err) {
                    if (err.response && err.response.status) {
                      const conf = window.confirm(
                        `Sendgrid key is already used`
                      );
                      if (!conf) return;
                    }
                  }
                }}
                value={sendgridKey}
                onChange={(e) => setSendgridKey(e.target.value)}
              />
              <TextField 
                label="Sendgrid Email"
                style={{
                  width: '400px',
                  padding: '5px'
                }}
                value={emailSender}
                onChange={(e) => setEmailSender(e.target.value)}
              />
            </>
          )}
          <div style={{width: '100%'}}>
            <ChipInput
              value={emails}
              onAdd={async (chip) => {
                chip = chip.trimRight();
                chip = chip.trimLeft();
                const res = await api.post('/api/v1/admin/basic-email/exist', { email: chip });
                if (res.data.length) {
                  const conf = window.confirm(
                    `Email already registered on system`
                  );
                  if (!conf) return;
                } else {
                  setEmails([...emails, chip]);     
                  setNewEmails([...newEmails, chip]);         
                }
              }}
              style={{
                width: '100%'
              }}
              onDelete={(chip) => {
                if (state) {
                  const del = [...delEmails];
                  del.push(chip.replaceAll(' ', '').split('-')[0]);
                  setDelEmails(del);
                } 
                setEmails(emails.filter(item => item !== chip))
                setNewEmails(newEmails.filter(item => item !== chip))
              }}
              margin="normal"
              label="Emails"
              variant="outlined"
            />
            {state && (
              <>
              <Typography>Import emails by CSV:</Typography>
              <a href="/dripmailer-sample-file.csv" download>Download sample file</a>
              <input
                helperText="Send Csv"
                onChange={emailCsv.onChange}
                style={{ display: 'none' }}
                name="emailcsv"
                type="file"
                id="upload"
              />
              <label htmlFor="upload">
                <Button variant="contained" component="span" size="small">
                  Upload
                </Button>
              </label>
              <i>{emailCsv.value ? emailCsv.value[0].name : 'No file selected'}</i>
              <Button
                disabled={!emailCsv.value}
                variant="contained"
                onClick={() => uploadCSV(emailCsv.value[0])}
                color="primary"
                size="small"
              >
                Save
              </Button>
              </>
            )}
          </div>
        
          <TextField 
            label="suject"
            style={{
              width: '800px',
              padding: '5px'
            }}
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          <TextField 
            label="text"
            style={{
              width: '800px',
              padding: '5px'
            }}
            value={text}
            rows={8}
            multiline
            onChange={(e) => setText(e.target.value)}
          />

          <Button
            disabled={ !category || !emails || !throttle || !subject || !text}
            style={{margin: '5px'}}
            onClick={() => {
              if (state) {
                editBasicEmail();
              } else {
                saveBasicEmail();
              }
            }}
            variant="contained"
            size="medium"
            color="primary"
          >
            Save
          </Button>
          <Button
            style={{margin: '5px'}}
            variant="contained"
            size="medium"
            onClick={() => props.history.goBack()}
          >
            CANCEL
          </Button>
        </div>
      </Container>
    </>
  )
};

export default AddBasicEmail;