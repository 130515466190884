import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from "@material-ui/core/styles";
import { Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  customShare: {
    '& .MuiDialog-paperWidthSm': {
      // maxWidth: '400px',
      width: '100%',
      borderRadius: '20px'
    },
    "& .MuiDialogContent-root": {
      padding: '0 !important',
    },
    "& .MuiListItemIcon-root": {
      minWidth: '46px',
      '& .MuiSvgIcon-root': {
        fontSize: '2rem',
      },
    },
    "& .MuiListItemText-inset": {
      paddingLeft: '46px',
    },
    "& .MuiDialogTitle-root": {
      textAlign: 'center',
    }
  },
}));

const Modal = (props) => {
  const classes = useStyles();

  return (
    <>
      <Dialog onClose={props.handleClose} open={props.open} className={classes.customShare}>
        <DialogTitle onClose={props.handleClose}>Emails</DialogTitle>
        <DialogContent dividers>
          <div style={{padding: '20px', display: 'flex', flexDirection: 'column'}}>
            {props.emails.map(item => (
              <h4>
                {item}
              </h4>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
};

export default Modal;