import React from 'react';
import { CircularProgress } from '@material-ui/core';

function loading() {
  return (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '80%'}}>
      <CircularProgress />
    </div>
  )
}

export default loading;