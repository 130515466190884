import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ReactDOM from "react-dom";

import restricted from "./components/restricted";

import Login from "./pages/login";
import Home from "./pages/home";
import Users from "./pages/users";
import DripEmail from "./pages/dripEmail";
import AddDripEmail from "./pages/dripEmail/AddDripEmail";
import EditDripEmail from "./pages/dripEmail/EditDripEmail";
import BasicEmail from "./pages/basicEmail";
import AddBasicEmail from "./pages/basicEmail/AddBasicEmail";
import ExcludeEmails from "./pages/exclude-emails";
import Logs from "./pages/basicEmail/Logs";
import SearchEmails from "./pages/searchEmail";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/login" exact component={Login} />
      <Route path="/" exact component={restricted(Home)} />
      <Route path="/users" exact component={restricted(Users)} />
      <Route path={"/drip-email"} exact component={restricted(DripEmail)} />
      <Route
        path={"/drip-email/add"}
        exact
        component={restricted(AddDripEmail)}
      />
      <Route
        path={"/drip-email/edit/:id"}
        exact
        component={restricted(EditDripEmail)}
      />
      <Route path={"/basic-email"} exact component={restricted(BasicEmail)} />
      <Route
        path={"/basic-email/add"}
        exact
        component={restricted(AddBasicEmail)}
      />
      <Route
        path={"/basic-email/edit"}
        exact
        component={restricted(AddBasicEmail)}
      />
      <Route
        path={"/basic-email/logs/:id"}
        exact
        component={restricted(Logs)}
      />
      <Route
        path={"/exclude-emails"}
        exact
        component={restricted(ExcludeEmails)}
      />
      <Route
        path={"/search-emails"}
        exact
        component={restricted(SearchEmails)}
      />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
