import React from "react";
import { TableView } from "../../components/table";
import axios from "../../services/api";
import { Container } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4),
  },
});

class Vendor extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      headers: {
        image: "Image",
        name: "Name",
        email: "Email",
        phone: 'Phone',
        lastLogin: "Last login",
        createdAt: "Created At",
      },
      totalData: 0,
      limit: 20,
      page: 0,
      status: 0,
      fin: false
    };
    this.onChangePage = this.onChangePage.bind(this);
    this.onChangeRowsPerPage = this.onChangeRowsPerPage.bind(this);
    this.paginate = this.paginate.bind(this);
    // this.deleteItem = this.deleteItem.bind(this);
    // this.suspendItem = this.suspendItem.bind(this);
  }

  async paginate(page, limit) {
    const lt = limit || this.state.limit;
    try {
      const url = `/api/v1/admin/users?page=${page + 1}&limit=${lt}`;
      const response = await axios({
        method: "get",
        url
      });
      this.setState({ limit: lt, rows: response.data.docs || [] });
    } catch (error) {
      console.error(error);
    }
  }

  async getUsers() {
    try {
      const response = await axios({
        method: "get",
        url: "/api/v1/admin/users"
      });
      this.setState({
        count: response.data.total,
        rows: response.data.docs || []
      });
    } catch (error) {
      console.error(error);
    }
  }

  // onBoardItem = async ({_id, name, onBoarding}) => {
  //   const conf = window.confirm(
  //     `Please confirm your action. Do you want to ${onBoarding ? 'remove' : 'show'} this Item (${name}) on boarding page?`
  //   );
  //   if (!conf) return;
  //   try {
  //     await axios.put(`/api/v1/users/onboarding/${_id}`, { onBoarding: !onBoarding });
  //     window.location.reload();
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // async deleteItem({ _id: id, name }) {
  //   const conf = window.confirm(
  //     `Please confirm your action. Do you want to delete this Item (${name})?`
  //   );
  //   if (!conf) return;
  //   try {
  //     await axios({
  //       method: "delete",
  //       url: `/api/v1/users/delete-user/${id}`
  //     });
  //     await this.paginate(this.state.page);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // async suspendItem({ _id: id, name, suspended }) {
  //   const conf = window.confirm(
  //     suspended ?
  //       `Please confirm your action. Do you want to unblock this User (${name})?`
  //       :
  //       `Please confirm your action. Do you want to suspend this User (${name})?`
  //   );
  //   if (!conf) return;
  //   try {
  //     await axios.put(`/api/v1/users/suspend-user/${id}`, {
  //       suspended: !suspended
  //     });
  //     await this.paginate(this.state.page);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  onChangePage(e, newPage) {
    this.setState({
      page: newPage,
      rows: []
    });
    this.paginate(newPage);
  }

  onChangeRowsPerPage(e, newLimit) {
    this.setState({ limit: e.currentTarget.value, rows: [] });
    this.paginate(this.state.page, e.currentTarget.value);
  }

  async componentDidMount() {
    this.setState({ status: 1 });
    await this.getUsers();
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <TableView
            rowsPerPageOptions={[20, 40, 80, 100]}
            count={this.state.count}
            page={this.state.page}
            rowsPerPage={this.state.limit}
            onChangeRowsPerPage={this.onChangeRowsPerPage}
            onChangePage={this.onChangePage}
            tableName={'users'}
            headers={this.state.headers}
            rows={this.state.rows}
            // fab={'/vendor/add'}
            // infiniteLoad={this.state.fin ? false : this.state.status === 1}
            // loadMore={this.loadMore}
            // onBoardItem={this.onBoardItem}
            // deleteItem={this.deleteItem}
            // suspendItem={this.suspendItem}
            paginate
          />
        </Container>
      </>
    );
  }
}

export default withStyles(styles)(Vendor);