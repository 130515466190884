import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import clsx from "clsx";

import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import PeopleIcon from "@material-ui/icons/People";
import EmailIcon from "@material-ui/icons/Email";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DescriptionIcon from "@material-ui/icons/Description";
import SearchIcon from "@material-ui/icons/Search";
import DripIcon from "@material-ui/icons/InvertColors";
import ListItemText from "@material-ui/core/ListItemText";
// import ExpandLess from "@material-ui/icons/ExpandLess";
// import ExpandMore from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";

import { logout, isAuthenticated } from "../../auth/authenticator";

const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    // flexGrow: 1,
    // marginBottom: "24px"
    display: "flex",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: "center",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  menuButtonHidden: {
    display: "none",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

export default function restricted(BaseComponent) {
  class Restricted extends Component {
    constructor() {
      super();
      this.state = {
        open: false,
      };
    }

    componentDidMount() {
      this.checkAuthentication(this.props);
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.location !== this.props.location) {
        this.checkAuthentication(nextProps);
      }
    }

    checkAuthentication(params) {
      const { history } = params;
      if (!isAuthenticated()) {
        history.replace({ pathname: "/login" });
      }
    }

    render() {
      const { classes } = this.props;
      const { open } = this.state;
      return (
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            position="absolute"
            className={clsx(classes.appBar, open && classes.appBarShift)}
          >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={() => this.setState({ open: true })}
                className={clsx(
                  classes.menuButton,
                  open && classes.menuButtonHidden
                )}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                onClick={() => this.props.history.push("/")}
                variant="h6"
                className={classes.title}
              >
                <a
                  href="/"
                  style={{
                    color: "white",
                    textDecoration: "none",
                  }}
                >
                  Jifcam
                </a>
              </Typography>
              {/* <Button color="inherit" onClick={() => this.props.history.push('/mails') }>Mails</Button> */}
              <Button
                color="inherit"
                onClick={() => {
                  logout();
                  this.props.history.push("/login");
                }}
              >
                Logout
              </Button>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            classes={{
              paper: clsx(
                classes.drawerPaper,
                !open && classes.drawerPaperClose
              ),
            }}
            open={open}
          >
            <div className={classes.toolbarIcon}>
              <IconButton onClick={() => this.setState({ open: false })}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <List>
              <ListItem button component={Link} to="/users">
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary={"Users"} />
              </ListItem>
              <ListItem button component={Link} to="/basic-email">
                <ListItemIcon>
                  <EmailIcon />
                </ListItemIcon>
                <ListItemText primary={"Basic email"} />
              </ListItem>
              <ListItem button component={Link} to="/drip-email">
                <ListItemIcon>
                  <DripIcon />
                </ListItemIcon>
                <ListItemText primary={"Drip email"} />
              </ListItem>
              <ListItem button component={Link} to="/exclude-emails">
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={"Exclude Emails"} />
              </ListItem>
              <ListItem button component={Link} to="/search-emails">
                <ListItemIcon>
                  <SearchIcon />
                </ListItemIcon>
                <ListItemText primary={"Search Emails"} />
              </ListItem>
              {/* <ListItem button component={Link} to="/newsletter">
                                <ListItemIcon>
                                    <DescriptionIcon />
                                </ListItemIcon>
                                <ListItemText primary={"Newsletter"} />
                            </ListItem> */}
            </List>
            {/* <Divider /> */}
            {/* <List>{secondaryListItems}</List> */}
          </Drawer>
          <main className={classes.content}>
            <BaseComponent {...this.props} />
          </main>
        </div>
      );
    }
  }

  return withRouter(withStyles(styles)(Restricted));
}
