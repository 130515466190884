import React from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Fab,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChipInput from "material-ui-chip-input";
import api from "../../services/api";
import Modal from "../../components/drip/modal";

const AddDripEmail = (props) => {
  const [emails, setEmails] = React.useState([]);
  const [emailSender, setEmailSender] = React.useState("");
  const [throttle, setThrottle] = React.useState(0);
  const [isSmtp, setIsSmtp] = React.useState(false);
  const [sendgridKey, setSendgridKey] = React.useState("");
  const [smtp, setSmtp] = React.useState({
    host: "",
    port: "",
    user: "",
    pwd: "",
  });
  const [openModal, setOpenModal] = React.useState(false);
  const [showInfo, setShowInfo] = React.useState(false);
  const [continueMailing, setContinueMailing] = React.useState(false);

  const [days, setDays] = React.useState([]);
  const [category, setCategory] = React.useState("");

  const saveDripEmail = async () => {
    const res = await api.post("/api/v1/admin/drip-email", {
      emails,
      days,
      category,
      throttle,
      sendgridKey,
      smtp,
      emailSender,
    });
    props.history.push(`/drip-email/edit/${res.data._id}`);
  };

  const handleChangeTextEmail = (e, idx) => {
    const newDays = [...days];
    newDays[idx]["textEmail"] = e.target.value;
    setDays(newDays);
  };

  const handleChangeSubject = (e, idx) => {
    const newDays = [...days];
    newDays[idx]["subject"] = e.target.value;
    setDays(newDays);
  };

  const addDay = (dayInput) => {
    if (days.find((item) => item.day == dayInput)) {
      const conf = window.confirm(`Day ${dayInput} already inserted`);
      setOpenModal(false);
      return;
    }
    const newDays = [...days];
    console.log(continueMailing);
    const dayObj = {
      day: dayInput,
      subject: "",
      textEmail: "",
      sent: 0,
      continueMailing,
    };
    newDays.push(dayObj);
    setDays(newDays);
    setOpenModal(false);
  };

  const deleteDay = (idx) => {
    const newDays = [...days];
    newDays.splice(idx, 1);
    setDays(newDays);
  };

  const onChangeSmtp = (e) => {
    const newSmtp = { ...smtp };
    newSmtp[e.target.name] = e.target.value;
    setSmtp(newSmtp);
  };

  const DXcheck = (dayInput) => {
    const calc = Math.floor(emails.length / throttle);
    if (calc > 1) {
      const conf = window.confirm(
        `it will take ${calc} hours to run the D${dayInput} throttle complete`
      );
      if (!conf) return;
    }
  };

  return (
    <>
      <Container style={{ marginTop: "100px" }}>
        <Typography component="h1" variant="h4" style={{ textAlign: "center" }}>
          Add Drip Email
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <TextField
            label="Category name"
            style={{
              width: "400px",
              padding: "5px",
            }}
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />
          <TextField
            label="Daily Throttle"
            type="number"
            style={{
              width: "400px",
              padding: "5px",
            }}
            value={throttle}
            onChange={(e) => setThrottle(e.target.value)}
          />
          <div>
            <label>smtp</label>
            <input
              type="checkbox"
              checked={isSmtp}
              onChange={() => {
                setIsSmtp(!isSmtp);
                setSmtp({ host: "", port: "", user: "", pwd: "" });
                setSendgridKey("");
              }}
            />
            <label>sendgrid</label>
            <input
              type="checkbox"
              checked={!isSmtp}
              onChange={() => {
                setIsSmtp(!isSmtp);
                setSmtp({ host: "", port: "", user: "", pwd: "" });
                setSendgridKey("");
              }}
            />
          </div>
          {isSmtp ? (
            <>
              <TextField
                label="Host"
                style={{
                  width: "400px",
                  padding: "5px",
                }}
                onBlur={async (e) => {
                  try {
                    await api.post("/api/v1/admin/drip-email/smtp", {
                      host: e.target.value,
                    });
                  } catch (err) {
                    if (err.response && err.response.status) {
                      const conf = window.confirm(`Smtp is already used`);
                      if (!conf) return;
                    }
                  }
                }}
                name="host"
                value={smtp.host}
                onChange={onChangeSmtp}
              />
              <TextField
                label="Port"
                type="number"
                style={{
                  width: "400px",
                  padding: "5px",
                }}
                name="port"
                value={smtp.port}
                onChange={onChangeSmtp}
              />
              <TextField
                label="user"
                style={{
                  width: "400px",
                  padding: "5px",
                }}
                name="user"
                value={smtp.user}
                onChange={onChangeSmtp}
              />
              <TextField
                label="password"
                style={{
                  width: "400px",
                  padding: "5px",
                }}
                name="pwd"
                value={smtp.pwd}
                onChange={onChangeSmtp}
              />
            </>
          ) : (
            <>
              <TextField
                label="Sendgrid Key"
                style={{
                  width: "400px",
                  padding: "5px",
                }}
                onBlur={async (e) => {
                  try {
                    await api.post("/api/v1/admin/drip-email/sendgrid", {
                      sendgridKey: e.target.value,
                    });
                  } catch (err) {
                    if (err.response && err.response.status) {
                      const conf = window.confirm(
                        `Sendgrid key is already used`
                      );
                      if (!conf) return;
                    }
                  }
                }}
                value={sendgridKey}
                onChange={(e) => setSendgridKey(e.target.value)}
              />
              <TextField
                label="Sendgrid Email"
                style={{
                  width: "400px",
                  padding: "5px",
                }}
                value={emailSender}
                onChange={(e) => setEmailSender(e.target.value)}
              />
            </>
          )}
          <div style={{ width: "100%" }}>
            <ChipInput
              value={emails}
              onAdd={async (chip) => {
                chip = chip.trimRight();
                chip = chip.trimLeft();
                const excludeRes = await api.post(
                  "/api/v1/admin/get-exclude-email",
                  {
                    email: chip,
                  }
                );
                if (!excludeRes.data) {
                  const res = await api.post("/api/v1/admin/drip-email/exist", {
                    email: chip,
                  });
                  if (res.data.length) {
                    const conf = window.confirm(
                      `Email already registered on system`
                    );
                    if (!conf) return;
                  } else {
                    setEmails([...emails, chip]);
                  }
                } else {
                  window.alert("Sorry this email is on the blacklist");
                }
              }}
              style={{
                width: "100%",
              }}
              onDelete={(chip) => {
                setEmails(emails.filter((item) => item !== chip));
              }}
              margin="normal"
              label="Emails"
              variant="outlined"
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography>Days:</Typography>
            <Fab
              onClick={() => {
                setOpenModal(true);
              }}
            >
              <AddIcon />
            </Fab>
          </div>
          {days.map((day, idx) => (
            <Accordion style={{ margin: 10, width: "100%" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${day.day}bh-content`}
                id={`panel${day.day}bh-header`}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowInfo(!showInfo);
                    }}
                  >
                    <Typography>
                      {`D${day.day}`} - {`${day.sent}/${emails.length}`}
                    </Typography>
                    {showInfo && (
                      <>
                        {day.info &&
                          day.info.map((item) => (
                            <span>
                              {item.sent} - {item.date} |{" "}
                            </span>
                          ))}
                      </>
                    )}
                  </div>
                  <DeleteIcon
                    onClick={(e) => {
                      e.stopPropagation(e);
                      deleteDay(idx);
                    }}
                  />
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ width: "100%" }}>
                  <TextField
                    label="Subject"
                    fullWidth
                    value={day.subject}
                    onChange={(e) => handleChangeSubject(e, idx)}
                  />
                  <TextField
                    label="Text Email"
                    fullWidth
                    value={day.textEmail}
                    rows={8}
                    multiline
                    onChange={(e) => handleChangeTextEmail(e, idx)}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
          <Button
            disabled={!category || !emails || !throttle}
            style={{ margin: "5px" }}
            onClick={() => {
              saveDripEmail();
            }}
            variant="contained"
            size="medium"
            color="primary"
          >
            Save
          </Button>
          <Button
            style={{ margin: "5px" }}
            variant="contained"
            size="medium"
            onClick={() => props.history.goBack()}
          >
            CANCEL
          </Button>
        </div>
      </Container>
      <Modal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        addDay={addDay}
        addContinueMailing={setContinueMailing}
        DXcheck={DXcheck}
      />
    </>
  );
};

export default AddDripEmail;
