import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import BlockIcon from "@material-ui/icons/Https";
import UnBlockIcon from "@material-ui/icons/NoEncryption";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import { useIsVisible } from "../../utils/hooks";
import { withRouter } from "react-router";
import { format, isValid } from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  loading: {
    width: "100%",
    textAlign: "center",
    padding: "15px 0",
    fontSize: 25,
  },
  fab: {
    float: "right",
  },
  table: {
    minWidth: 650,
  },
}));

export const TableView = withRouter((props) => {
  const classes = useStyles();
  const { rows, headers } = props;
  const element = React.useRef(null);
  const [state, setState] = React.useState({
    open: false,
    anchorEl: null,
    data: [],
    popupKey: "01popup",
  });
  const handleClick = (data, key) => (event) => {
    if (Array.isArray(data)) {
      setState({
        ...state,
        data,
        popupKey: key,
        open: !state.open,
        anchorEl: event.currentTarget,
      });
    }
  };
  const showCd = (show) => {
    if (show) {
      props.loadMore();
    }
  };
  const showData = (data) => {
    const rgx = /[0-9][0-9][0-9][0-9]-[0-9][0-9]/;
    if (typeof data === "string" && data.includes("https://")) {
      return <Avatar src={data} />;
    } else if (data === false) {
      return "False";
    } else if (data === true) {
      return "True";
    } else if (rgx.test(data)) {
      return format(new Date(data), "yyyy-MM-dd HH:ii:ss");
    } else {
      return data;
    }
  };

  useIsVisible(element, false, showCd, props.infiniteLoad);
  const onClickName = (row) => {
    if (props.tableName.includes("users")) {
      props.history.push(`/users/detail/${row._id}`, { user: row });
    }
  };
  return (
    <div className={classes.root}>
      <Popper
        open={state.open}
        anchorEl={state.anchorEl}
        placement="top"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <List component="nav" aria-label="secondary mailbox folders">
                {state.data.map((item) => (
                  <ListItem
                    onClick={() => setState({ ...state, open: false })}
                    button
                  >
                    <ListItemText
                      primary={item.channel ? item.channel.name : item.name}
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Fade>
        )}
      </Popper>
      {props.fab && (
        <Fab
          component={Link}
          to={props.fab}
          color="primary"
          aria-label="add"
          className={classes.fab}
        >
          <AddIcon />
        </Fab>
      )}
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {Object.values(headers).map((h) => (
              <TableCell
                style={{ fontSize: 10, padding: 10 }}
                key={h}
                align="center"
              >
                {h}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            // console.log(row);
            return (
              <TableRow key={row._id}>
                {Object.keys(headers).map((cell) => {
                  let cellInf = row[cell];
                  if (typeof row[cell] === "object" && row[cell] !== null) {
                    if (row[cell].name) {
                      cellInf = row[cell].name;
                    }
                  }
                  return (
                    <TableCell
                      style={{ fontSize: 10, padding: 5 }}
                      key={
                        Array.isArray(row[cell])
                          ? row._id
                          : row._id + "" + row[cell] + cell + row["createdAt"]
                      }
                      onClick={() => {
                        !Array.isArray(row[cell]) &&
                          setState({ ...state, open: false });
                        cell === "name" && onClickName(row);
                      }}
                      align="center"
                    >
                      {Array.isArray(row[cell]) ? (
                        <Button
                          onClick={() => {
                            if (props.modal && cell == "emails") {
                              props.openModal(row);
                            } else {
                              handleClick(row[cell], row._id + "Popup");
                            }
                          }}
                          variant="contained"
                          className={classes.button}
                        >
                          {row[cell].length}
                        </Button>
                      ) : (
                        showData(cellInf)
                      )}

                      {cell === "delete" && (
                        <Fab
                          color="secondary"
                          onClick={() => props.deleteItem(row)}
                          aria-label="delete"
                          size="small"
                        >
                          <DeleteIcon />
                        </Fab>
                      )}

                      {cell === "edit" && (
                        <Fab
                          color="secondary"
                          onClick={() => props.editItem(row)}
                          aria-label="edit"
                          size="small"
                        >
                          <EditIcon />
                        </Fab>
                      )}

                      {cell === "send" && (
                        <Button
                          onClick={() => props.firstSend(row)}
                          size="small"
                          disabled={
                            props.tableName === "drip"
                              ? row.days.find(
                                  (item) => item.day === 0 && item.sent === 0
                                )
                                ? false
                                : true
                              : row.batchActive
                          }
                          variant="contained"
                        >
                          Send
                        </Button>
                      )}

                      {cell === "test" && (
                        <Button
                          onClick={() => props.handleTestEmail(row)}
                          size="small"
                          variant="contained"
                        >
                          Test
                        </Button>
                      )}

                      {cell === "transfer" && (
                        <Button
                          onClick={() => props.immediate(row)}
                          size="small"
                          variant="contained"
                          disabled={
                            row.days.find((item) => item.day === 0)
                              ? false
                              : true
                          }
                        >
                          Transfer
                        </Button>
                      )}

                      {cell === "logs" && (
                        <Button
                          onClick={() =>
                            props.history.push(`/basic-email/logs/${row._id}`)
                          }
                          size="small"
                          variant="contained"
                        >
                          Logs
                        </Button>
                      )}

                      {cell === "suspend" && row["suspended"] && (
                        <Fab
                          color="secondary"
                          onClick={() => props.suspendItem(row)}
                          aria-label="delete"
                          size="small"
                        >
                          <UnBlockIcon />
                        </Fab>
                      )}

                      {cell === "suspend" && !row["suspended"] && (
                        <Fab
                          color="secondary"
                          onClick={() => props.suspendItem(row)}
                          aria-label="delete"
                          size="small"
                        >
                          <BlockIcon />
                        </Fab>
                      )}

                      {cell === "onBoard" && row["onBoarding"] && (
                        <Fab
                          onClick={() => props.onBoardItem(row)}
                          style={{ color: "red" }}
                          size="small"
                        >
                          <CancelIcon />
                        </Fab>
                      )}

                      {cell === "onBoard" && !row["onBoarding"] && (
                        <Fab
                          style={{ color: "green" }}
                          onClick={() => props.onBoardItem(row)}
                          size="small"
                        >
                          <CheckCircleIcon />
                        </Fab>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
        {props.paginate && (
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={Object.values(headers).length}
                count={props.count}
                page={props.page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                rowsPerPageOptions={props.rowsPerPageOptions}
                rowsPerPage={props.rowsPerPage}
                onChangePage={props.onChangePage}
                onChangeRowsPerPage={props.onChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>

      <section ref={element}>
        {props.infiniteLoad && (
          <div className={classes.loading}>Loading...</div>
        )}
      </section>
    </div>
  );
});
