import React from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { useInputValue } from "../../utils/hooks";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChipInput from "material-ui-chip-input";
import api from "../../services/api";
import Modal from "../../components/drip/modal";
import Loading from "../../components/loading";
import { format } from "date-fns";
import moment from "moment";
import { CSVLink } from "react-csv";

const headers = [{ label: "email", key: "email" }];

const AddDripEmail = (props) => {
  const [emails, setEmails] = React.useState([]);
  const [emailSender, setEmailSender] = React.useState("");
  const [throttle, setThrottle] = React.useState(0);
  const [isSmtp, setIsSmtp] = React.useState(false);
  const [sendgridKey, setSendgridKey] = React.useState("");
  const [smtp, setSmtp] = React.useState({
    host: "",
    port: "",
    user: "",
    pwd: "",
  });
  const emailCsv = useInputValue("", false, true);
  const [newEmails, setNewEmails] = React.useState([]);
  const [delEmails, setDelEmails] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [showInfo, setShowInfo] = React.useState(false);
  const [dates, setDates] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [emailsCsv, setEmailsCsv] = React.useState([]);

  const [days, setDays] = React.useState([]);
  const [category, setCategory] = React.useState("");
  const [oldest, setOldest] = React.useState(0);
  const [newest, setNewest] = React.useState(0);
  const [logs, setLogs] = React.useState([]);
  const [continueMailing, setContinueMailing] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    api.get(`/api/v1/admin/drip-email/${props.match.params.id}`).then((res) => {
      setLoading(false);
      setEmails(res.data.emails ? res.data.emails : []);
      setThrottle(res.data.throttle);
      setDays(res.data.days);
      setCategory(res.data.category);
      setDates(res.data.dates ? res.data.dates : []);
      if (res.data.sendgridKey) {
        setSendgridKey(res.data.sendgridKey);
        setEmailSender(res.data.emailSender);
        setIsSmtp(false);
      } else {
        setIsSmtp(true);
        setSmtp(res.data.smtp);
      }
      setOldest(res.data.oldest);
      setNewest(res.data.newest);
      setEmailsCsv(
        res.data.emails
          ? res.data.emails.map((item) => {
              return { email: item.replaceAll(" ", "").split("-")[0] };
            })
          : []
      );
      getLogs();
    });
  }, []);

  const editDripEmail = async (emailsDup = false) => {
    await api.put(`/api/v1/admin/drip-email/${props.match.params.id}`, {
      emails: emailsDup ? emailsDup : newEmails,
      days,
      category,
      throttle,
      sendgridKey,
      smtp,
      emailSender,
      delEmails,
    });
    window.location.reload();
  };

  const handleChangeTextEmail = (e, idx) => {
    const newDays = [...days];
    newDays[idx]["textEmail"] = e.target.value;
    setDays(newDays);
  };

  const handleChangeSubject = (e, idx) => {
    const newDays = [...days];
    newDays[idx]["subject"] = e.target.value;
    setDays(newDays);
  };

  const addDay = (dayInput) => {
    if (days.find((item) => item.day == dayInput)) {
      const conf = window.confirm(`Day ${dayInput} already inserted`);
      setOpenModal(false);
      return;
    }
    const newDays = [...days];
    const dayObj = {
      day: dayInput,
      subject: "",
      textEmail: "",
      sent: 0,
      continueMailing,
    };
    newDays.push(dayObj);
    setDays(newDays);
    setOpenModal(false);
  };

  const deleteDay = (idx) => {
    const newDays = [...days];
    newDays.splice(idx, 1);
    setDays(newDays);
  };

  const onChangeSmtp = (e) => {
    const newSmtp = { ...smtp };
    newSmtp[e.target.name] = e.target.value;
    setSmtp(newSmtp);
  };

  const uploadCSV = async (data) => {
    try {
      let formData = new FormData();
      formData.append("emailCsv", data);
      const res = await api.post(
        `/api/v1/admin/drip-email-csv/${props.match.params.id}`,
        formData
      );
      if (res.data.duplicates.length) {
        const conf = window.confirm(
          `${res.data.duplicates.join()} exists. Would you still like to import them?`
        );
        if (conf) {
          const emailsDup = res.data.duplicates.map(
            (item) => item.replaceAll(" ", "").split("-")[0]
          );
          setEmails([...emails, ...emailsDup]);
          await editDripEmail(emailsDup);
        } else {
          window.location.reload();
        }
      } else {
        window.location.reload();
      }
    } catch (err) {
      if (err.response) {
        const conf = window.confirm(`${err.response.data.message}`);
        if (!conf) return;
      }
    }
  };

  const DXcheck = (dayInput) => {
    const calc = Math.ceil(emails.length / throttle);
    if (calc >= 1) {
      const conf = window.confirm(
        `it will take ${calc} hours to run the D${dayInput} throttle complete`
      );
      if (!conf) return;
    }
  };

  const getLogs = async () => {
    try {
      const data = (
        await api.get(`/api/v1/admin/get-logs/${props.match.params.id}`)
      ).data;

      setLogs(data);
    } catch (e) {
      console.log(e);
    }
  };

  const checkOldest = (day) => {
    if (day.oldest && day.newest) {
      return `Oldest Sent: ${format(
        new Date(day.oldest),
        "MMM d yyyy"
      )} / Newest Sent: ${format(new Date(day.newest), "MMM d yyyy")}`;
    } else {
      return "";
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Container style={{ marginTop: "100px" }}>
        <Typography component="h1" variant="h4" style={{ textAlign: "center" }}>
          Edit Drip Email
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <TextField
            label="Category name"
            style={{
              width: "400px",
              padding: "5px",
            }}
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />
          <TextField
            label="Daily Throttle"
            type="number"
            style={{
              width: "400px",
              padding: "5px",
            }}
            value={throttle}
            onChange={(e) => setThrottle(e.target.value)}
          />
          <div>
            <label>smtp</label>
            <input
              type="checkbox"
              checked={isSmtp}
              onChange={() => {
                setIsSmtp(!isSmtp);
                setSmtp({ host: "", port: "", user: "", pwd: "" });
                setSendgridKey("");
              }}
            />
            <label>sendgrid</label>
            <input
              type="checkbox"
              checked={!isSmtp}
              onChange={() => {
                setIsSmtp(!isSmtp);
                setSmtp({ host: "", port: "", user: "", pwd: "" });
                setSendgridKey("");
              }}
            />
          </div>
          {isSmtp ? (
            <>
              <TextField
                label="Host"
                style={{
                  width: "400px",
                  padding: "5px",
                }}
                onBlur={async (e) => {
                  try {
                    await api.post("/api/v1/admin/drip-email/smtp", {
                      host: e.target.value,
                    });
                  } catch (err) {
                    if (err.response && err.response.status) {
                      const conf = window.confirm(`Smtp is already used`);
                      if (!conf) return;
                    }
                  }
                }}
                name="host"
                value={smtp.host}
                onChange={onChangeSmtp}
              />
              <TextField
                label="Port"
                type="number"
                style={{
                  width: "400px",
                  padding: "5px",
                }}
                name="port"
                value={smtp.port}
                onChange={onChangeSmtp}
              />
              <TextField
                label="user"
                style={{
                  width: "400px",
                  padding: "5px",
                }}
                name="user"
                value={smtp.user}
                onChange={onChangeSmtp}
              />
              <TextField
                label="password"
                style={{
                  width: "400px",
                  padding: "5px",
                }}
                name="pwd"
                value={smtp.pwd}
                onChange={onChangeSmtp}
              />
            </>
          ) : (
            <>
              <TextField
                label="Sendgrid Key"
                style={{
                  width: "400px",
                  padding: "5px",
                }}
                onBlur={async (e) => {
                  try {
                    await api.post("/api/v1/admin/drip-email/sendgrid", {
                      sendgridKey: e.target.value,
                    });
                  } catch (err) {
                    if (err.response && err.response.status) {
                      const conf = window.confirm(
                        `Sendgrid key is already used`
                      );
                      if (!conf) return;
                    }
                  }
                }}
                value={sendgridKey}
                onChange={(e) => setSendgridKey(e.target.value)}
              />
              <TextField
                label="Sendgrid Email"
                style={{
                  width: "400px",
                  padding: "5px",
                }}
                value={emailSender}
                onChange={(e) => setEmailSender(e.target.value)}
              />
            </>
          )}
          <div style={{ width: "100%" }}>
            <ChipInput
              value={emails}
              onAdd={async (chip) => {
                chip = chip.trimRight();
                chip = chip.trimLeft();
                const excludeRes = await api.post(
                  "/api/v1/admin/get-exclude-email",
                  {
                    email: chip,
                  }
                );
                if (!excludeRes.data) {
                  const res = await api.post("/api/v1/admin/drip-email/exist", {
                    email: chip,
                  });
                  if (res.data.length) {
                    const conf = window.confirm(
                      `Email already registered on system`
                    );
                    if (!conf) return;
                  } else {
                    setNewEmails([...newEmails, chip]);
                    setEmails([...emails, chip]);
                  }
                } else {
                  window.alert("Sorry this email is on the blacklist");
                }
              }}
              style={{
                width: "100%",
              }}
              onDelete={(chip) => {
                const del = [...delEmails];
                del.push(chip.replaceAll(" ", "").split("-")[0]);
                setDelEmails(del);
                setEmails(emails.filter((item) => item !== chip));
                setNewEmails(newEmails.filter((item) => item !== chip));
              }}
              margin="normal"
              label="Emails"
              variant="outlined"
            />

            {dates.map((item) => (
              <span>
                {item.count} emails added at{" "}
                {format(new Date(item._id), "yyyy-MM-dd HH:ii:ss")}
                <br />
              </span>
            ))}

            <Typography>Import emails by CSV:</Typography>
            <a href="/dripmailer-sample-file.csv" download>
              Download sample file
            </a>
            <input
              helperText="Send Csv"
              onChange={emailCsv.onChange}
              style={{ display: "none" }}
              name="emailcsv"
              type="file"
              id="upload"
            />
            <label htmlFor="upload">
              <Button variant="contained" component="span" size="small">
                Upload
              </Button>
            </label>
            <i>
              {emailCsv.value ? emailCsv.value[0].name : "No file selected"}
            </i>
            <Button
              disabled={!emailCsv.value}
              variant="contained"
              onClick={() => uploadCSV(emailCsv.value[0])}
              color="primary"
              size="small"
            >
              Save
            </Button>
            <div style={{ marginTop: "10px" }}>
              <CSVLink
                data={emailsCsv}
                headers={headers}
                filename={"drip-emails.csv"}
              >
                Export Emails to CSV
              </CSVLink>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography>{`Days: (Today Oldest: D${oldest}, Newest: D${newest})`}</Typography>
            <Fab
              onClick={() => {
                setOpenModal(true);
              }}
            >
              <AddIcon />
            </Fab>
          </div>
          {days.map((day, idx) => (
            <Accordion style={{ margin: 10, width: "100%" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${day.day}bh-content`}
                id={`panel${day.day}bh-header`}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowInfo(!showInfo);
                    }}
                  >
                    <Typography>
                      {`D${day.day}`} -
                      {`${day.sent}/${
                        emails.length + newEmails.length
                      } - ${checkOldest(day)}`}
                    </Typography>
                    {showInfo && (
                      <>
                        {day.info &&
                          day.info.map((item) => (
                            <span>
                              {item.sent} -{" "}
                              {format(
                                new Date(item.date),
                                "yyyy-MM-dd HH:ii:ss"
                              )}{" "}
                              |{" "}
                            </span>
                          ))}
                      </>
                    )}
                  </div>
                  <DeleteIcon
                    onClick={(e) => {
                      e.stopPropagation(e);
                      deleteDay(idx);
                    }}
                  />
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ width: "100%" }}>
                  <TextField
                    label="Subject"
                    fullWidth
                    value={day.subject}
                    onChange={(e) => handleChangeSubject(e, idx)}
                  />
                  <TextField
                    label="Text Email"
                    fullWidth
                    value={day.textEmail}
                    rows={8}
                    multiline
                    onChange={(e) => handleChangeTextEmail(e, idx)}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
          <Button
            disabled={!category || !emails || !throttle}
            style={{ margin: "5px" }}
            onClick={() => {
              editDripEmail();
            }}
            variant="contained"
            size="medium"
            color="primary"
          >
            Save
          </Button>
          <Button
            style={{ margin: "5px" }}
            variant="contained"
            size="medium"
            onClick={() => props.history.push("/drip-email")}
          >
            CANCEL
          </Button>
        </div>
        <TableContainer
          component={Paper}
          style={{
            marginTop: 20,
            marginBottom: 80,
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell align="right">Type</TableCell>
                <TableCell align="right">Source</TableCell>
                <TableCell align="right">Batch</TableCell>
                <TableCell align="right">Date</TableCell>
                <TableCell align="right">Subject</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((row) => (
                <TableRow
                  key={row._id}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.email}
                  </TableCell>
                  <TableCell align="right">{row.type}</TableCell>
                  <TableCell align="right">{row.source}</TableCell>
                  <TableCell align="right">{row.batch}</TableCell>
                  <TableCell align="right">
                    {moment(row.date).format("MMM DD YYYY")}
                  </TableCell>
                  <TableCell align="right">{row.subject}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Modal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        addDay={addDay}
        addContinueMailing={setContinueMailing}
        DXcheck={DXcheck}
      />
    </>
  );
};

export default AddDripEmail;
