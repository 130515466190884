import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  customShare: {
    "& .MuiDialog-paperWidthSm": {
      // maxWidth: '400px',
      width: "100%",
      borderRadius: "20px",
    },
    "& .MuiDialogContent-root": {
      padding: "0 !important",
    },
    "& .MuiListItemIcon-root": {
      minWidth: "46px",
      "& .MuiSvgIcon-root": {
        fontSize: "2rem",
      },
    },
    "& .MuiListItemText-inset": {
      paddingLeft: "46px",
    },
    "& .MuiDialogTitle-root": {
      textAlign: "center",
    },
  },
}));

const Modal = (props) => {
  const classes = useStyles();

  const [day, setDay] = React.useState("");
  const [continueMailing, setContinueMailing] = React.useState(false);

  return (
    <>
      <Dialog
        onClose={props.handleClose}
        open={props.open}
        className={classes.customShare}
      >
        <DialogTitle onClose={props.handleClose}>
          Choose day for email
        </DialogTitle>
        <DialogContent dividers>
          <div
            style={{
              padding: "20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>
              <select
                style={{ height: "30px", width: "300px", margin: "5px" }}
                value={day}
                onChange={(e) => {
                  props.DXcheck(e.target.value);
                  setDay(e.target.value);
                }}
              >
                <option value="" disabled selected>
                  choose day
                </option>
                <option value={0}>{`Day ${0}`}</option>
                {Array(100)
                  .fill()
                  .map(Number.call, Number)
                  .map((day) => {
                    if (day > 0) {
                      return (
                        <option key={day} value={day}>{`Day ${day}`}</option>
                      );
                    }
                  })}
              </select>
              <label>
                Continue Maling
                <input
                  type="checkbox"
                  style={{
                    padding: 20,
                  }}
                  checked={continueMailing}
                  onChange={(e) => setContinueMailing(e.target.checked)}
                />
              </label>
            </div>
            <Button
              style={{ margin: "5px" }}
              disabled={!day}
              variant="contained"
              onClick={() => {
                props.addContinueMailing(continueMailing);
                setContinueMailing(false);
                props.addDay(day);
                setDay("");
              }}
            >
              Save
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Modal;
