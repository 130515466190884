import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Fab, Container } from "@material-ui/core";
import { Link } from 'react-router-dom';
import api from "../../services/api";
import { TableView } from "../../components/table";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 10
  },
  margin: {
    marginTop: 70
  }
}));

const Index = (props) => {
  const classes = useStyles();

  const [logs, setLogs] = React.useState([]);

  React.useEffect(() => {
    api.get(`/api/v1/admin/basic-email/logs/${props.match.params.id}`).then(res => {
      setLogs(res.data.log.reverse());
    });
  }, []);

  console.log(props)

  return (
    <Container className={classes.margin}>
      <center><Typography component="h1" variant="h4">Basic Email Logs</Typography></center>
      <TableView
        headers={{
          email: 'Email', 
          batch: 'Batch',
          source: 'Source', 
          smtp: 'Smtp', 
          sendgridKey: "Sengrid",
          throttle: 'Throttle',
          sent: 'Sent',
          date: 'Date',
          status: 'Status',
          subject: 'Subject',
          text: 'Text'
        }}
        rows={logs}
      />
    </Container>
  );
}

export default Index;