import React, { useEffect, useState, useCallback } from "react";
import axios from "../../services/api";
// import { Container } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import api from "../../services/api";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4),
  },
});

const ExcludeEmail = () => {
  const [emails, setEmails] = useState([]);
  const [email, setEmail] = useState("");
  const [selected, setSelected] = useState("");

  const getEmailsExclude = useCallback(async () => {
    const { data } = await axios.get("/api/v1/admin/get-exclude-emails");
    setEmails(data);
  }, []);

  const deleteDripEmail = async (e) => {
    e.preventDefault();
    const w = window.confirm(
      "Are you sure to delete this email from the blacklist?"
    );
    if (w) {
      await api.post("/api/v1/admin/delete-exclude-emails", {
        email: selected,
      });
      setSelected("");
      getEmailsExclude();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const w = window.confirm(
      "Are you sure to add this email to the blacklist?"
    );
    if (w) {
      await api.post("/api/v1/admin/exclude-emails", {
        email,
      });
      setEmail("");
      getEmailsExclude();
    }
  };

  useEffect(() => {
    getEmailsExclude();
  }, [getEmailsExclude]);

  return (
    <Container style={{ marginTop: "100px" }}>
      <Typography component="h1" variant="h4" style={{ textAlign: "center" }}>
        Exclude Emails
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <form
          style={{
            marginTop: 50,
            width: 400,
          }}
          onSubmit={handleSubmit}
        >
          <TextField
            type="email"
            placeholder="Email"
            fullWidth
            value={email}
            name="email"
            onChange={(e) => setEmail(e.target.value)}
          />

          <Button
            type="submit"
            disabled={!email}
            style={{ margin: "5px" }}
            variant="contained"
            fullWidth
            color="primary"
          >
            Save
          </Button>
        </form>
        <form
          style={{
            width: 400,
            marginTop: 40,
          }}
          onSubmit={deleteDripEmail}
        >
          <Select
            fullWidth
            variant="filled"
            value={selected}
            onChange={(e) => setSelected(e.target.value)}
          >
            {emails.map((item) => (
              <MenuItem key={item._id} value={item.email}>
                {item.email}
              </MenuItem>
            ))}
          </Select>
          <Button
            type="submit"
            disabled={!selected}
            style={{ margin: "5px" }}
            variant="contained"
            fullWidth
            color="secondary"
          >
            Delete
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default withStyles(styles)(ExcludeEmail);
