import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Fab, Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import api from "../../services/api";
import Loading from "../../components/loading";
import { TableView } from "../../components/table";
import ModalEmail from "../../components/drip/modalEmail";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 10,
  },
  margin: {
    marginTop: 70,
  },
}));

const Index = (props) => {
  const classes = useStyles();

  const [basicEmails, setBasicEmails] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [limit] = React.useState(100);
  const [emails, setEmails] = React.useState([]);

  const getData = useCallback(async (pg, lt) => {
    pg = pg ? pg + 1 : page + 1;
    lt = lt ? lt : limit;
    const data = (
      await api.get(`/api/v1/admin/basic-email?page=${pg}&limit=${lt}`)
    ).data;
    if (data) {
      setTotal(data.total);
      setPage(data.page);
      setBasicEmails(data.docs);
    }
  }, []);

  const deleteItem = async ({ _id, category }) => {
    const conf = window.confirm(
      `Please confirm your action. Do you want to delete this Item (${category})?`
    );
    if (!conf) return;
    try {
      await api({
        method: "delete",
        url: `/api/v1/admin/basic-email/${_id}`,
      });
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const editItem = (row) => {
    props.history.push("/basic-email/edit", { data: row });
  };

  const firstSend = async ({ _id }) => {
    setLoading(true);
    try {
      await api.post(`/api/v1/admin/basic-email/first-batch/${_id}`);
      setLoading(false);
      window.location.reload();
    } catch (err) {
      setLoading(false);
      const conf = window.confirm(`Error occurred during send emails`);
      if (!conf) return;
    }
  };

  const openModal = (row) => {
    setEmails(row.emails);
    setOpen(true);
  };

  const onChangePage = (e, newPage) => {
    setBasicEmails([]);
    setPage(newPage);
    getData(newPage);
  };

  const handleTestEmail = async ({ _id }) => {
    setLoading(true);
    try {
      await api.post(`/api/v1/admin/basic-email/first-batch/${_id}?test=true`);
      setLoading(false);
      window.location.reload();
    } catch (err) {
      setLoading(false);
      const conf = window.confirm(`Error occurred during send emails`);
      if (!conf) return;
    }
  };

  React.useEffect(() => {
    getData();
  }, [getData]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Container className={classes.margin}>
      <center>
        <Typography component="h1" variant="h4">
          Basic Email
        </Typography>
      </center>
      <Fab
        component={Link}
        to={"/basic-email/add"}
        color="primary"
        aria-label="add"
        style={{ float: "right" }}
      >
        <AddIcon />
      </Fab>
      <TableView
        headers={{
          category: "Category",
          emails: "Emails",
          sent: "Sent",
          createdAt: "Created At",
          updatedAt: "Updated At",
          logs: "",
          edit: "",
          delete: "",
          test: "",
          send: "",
        }}
        rows={basicEmails}
        deleteItem={deleteItem}
        u
        handleTestEmail={handleTestEmail}
        editItem={editItem}
        firstSend={firstSend}
        openModal={openModal}
        modal={true}
        count={total}
        page={page}
        rowsPerPage={limit}
        onChangePage={onChangePage}
        paginate
      />
      <ModalEmail
        open={open}
        handleClose={() => setOpen(false)}
        emails={emails}
      />
    </Container>
  );
};

export default Index;
